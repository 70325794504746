import React, {Suspense} from "react";
import {useRoutes} from "react-router-dom";

//components
import Root from "./Root";


const index4 = React.lazy(() => import('../pages/landings/Index4'));


const loading = () => <div className=""></div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component/>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes([
        {
            //root route
            path: '/',
            element: <Root/>
        },
        {
            //public routes
            path: '/',
            children: [
                {
                    path: 'landing',
                    element: <LoadComponent component={index4}/>,
                },
                {
                    path: 'landing',
                    children: [
                        {path: 'index4', element: <LoadComponent component={index4}/>},
                    ],
                },
                {
                },
            ]
        }
    ])
}

export default AllRoutes;
